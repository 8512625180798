<template>
	<div id="app">
		<keep-alive>
			<router-view />
		</keep-alive>
		<div class="loading-app" v-if="showLoading">
			<div class="loading-box">
				<img src="/assets/loading.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	// https://www.129kok.com:3005/app/european-cup/108
	import {
		mapActions,
		mapGetters
	} from 'vuex';
	export default {
		name: "app",
		data() {
			return {
				showLoading: true,
			};
		},
		mounted() {
			if (window.location.pathname.indexOf('/game') >= 0) {
				this.showLoading = false
			} else {
				if (localStorage.getItem('initOpen')) {
					// 隐藏首次loading
					setTimeout(() => {
						this.showLoading = false
					}, 1500)
				} else {
					localStorage.setItem('initOpen', 1)
					// 隐藏首次loading
					setTimeout(() => {
						this.showLoading = false
					}, 3000)
				}
			}
			
			// 配置提示框
			this.$message.config({
				top: `300px`,
				duration: 2
			});
			// 获取配置
			this.setConfig()
			this.getBanner()
			
			// 判断是否有token，获取用户余额
			if (localStorage.getItem("access_token")) {
				this.setBalance()
				this.intervalGetBalance()
				this.getUnread()
			}
			
			
			
			setTimeout(() => {
				// 获取游戏
				this.setGame()
			}, 1000)
		},
		methods: {
			...mapActions('config', ['setConfig', 'setGame', 'getBanner']),
			...mapActions('user', ['intervalGetBalance', 'setBalance', 'clearIntervalGetBalance', 'getUnread']),
		}
	};
</script>

<style>
	.bboodds .ant-modal-header{
		background-color: #f0f2f3;
	}
	.meunlistcss ul {
		height: 250px;
		overflow: auto;
	}
	.inputhe60 .ainputnumber .ant-input-number-input{
		height: 60px;
		font-size: 18px;
	}
	.meunlistcss .ant-dropdown-menu {
		padding: 10px 0;
	}
	.meunlistcss .ant-dropdown-menu li {
		margin-bottom: 3px;
		font-size: 14px;
	}
	.password-pop .ant-popover-inner-content {
		padding:0px !important;
	}
	.password-pop .ant-popover-inner-content .ant-popover-message {
		padding:0px !important;
	}
	.password-pop .ant-popover-inner-content .ant-popover-message-title {
		padding-left: 0px !important;
	}
	.password-pop .anticon-exclamation-circle {
		display: none;
	}
	.password-pop .ant-popover-buttons {
		display: none;
	}
	.password-pop .ant-popover-arrow{
		border-top-color: #1675c1 !important;
		    border-right-color: transparent;
		    border-bottom-color: transparent;
		    border-left-color: #1675c1 !important;
	}
	.inputnoright .ant-input-number-handler-wrap{
		display: none;
	}
	
	.bulletin-model .ant-modal-body{
		padding-top: 0px;
	}
	.private_avatar .ant-upload.ant-upload-select-picture-card{
		width: 90px;
		    height: 90px;
			border-radius: 100px;
			border:none;
	}
	.private_avatar .ant-upload.ant-upload-select-picture-card img{
		width: 90px;
		    height: 90px;
			border-radius: 100px;
	}
	.private_avatar .ant-upload.ant-upload-select-picture-card > .ant-upload {
		padding: 0px;
	}
	.codemodel1 .ant-modal-body{
		padding: 8px;
	}
	.codemodel1 .ant-modal-content {
		border-radius: 0px !important;
	}
	.codemodel1 .ant-modal-close-x {
		display:none;
	}
	.codemodel1.modelerror{
		animation: geetest_shake 0.2s linear infinite both;
	}
	@keyframes geetest_shake {
		25% {
			margin-left: -6px
		}
	
		75% {
			margin-left: 6px
		}
	
		100% {
			margin-left: 0
		}
	}
	input {
		outline: none;
	}
	button {
		outline: none;
		border:none;
	}
	.codemodel1 .ant-modal-close {
		width:16px;
		height:16px;
	}
	.codemodel1 .ant-modal-close .ant-modal-close-x {
		width:16px;
		height:16px;
		    top: -14px;
		    right: 4px;
	}
	.game_maintain {
		pointer-events: none;
		position: relative;
		filter: grayscale(100%)
	}
	/* .game_maintain>div {
		filter: grayscale(100%)
	}
	.game_maintain>p {
		filter: grayscale(100%)
	}
	.game_maintain>img {
		filter: grayscale(100%)
	} */
	.nopagafter .game_maintain::after {
		display: none;
	}
	.game_maintain::after {
		content: '';
		    font-size: 30px;
		    position: absolute;
		    top: 80%;
		    left: 50%;
		    transform: translate(-50%, -50%);
		    font-weight: 700;
			color:red;
			/* background-image: url(./assets/whz.png); */
			background-image: url(/assets/whz.png);
			    width: 100%;
			    height: 100%;
			    background-repeat: no-repeat;
			    background-position: center;
			    background-size: contain;
				    z-index: 111;
					opacity: 0.8;
	}
	.header_div_1 .game_maintain::after {
		width: 90% !important;
	}
	.game_maintain.game_maintain_dz::after {
		width: 60% !important;
		top: 50% !important;
	}
	.game_maintain.game_maintain_by::after {
		top: 55% !important;
	}
	.game_maintain.game_maintain_icon::after {
		display:none;
	}
	.game_maintain.game_maintain_icon>div:nth-child(1)::after {
		content: '';
		    font-size: 30px;
		    position: absolute;
		    top: 76%;
		    left: 50%;
		    transform: translate(-50%, -50%);
		    font-weight: 700;
			color:red;
			/* background-image: url(./assets/whz.png); */
			background-image: url(/assets/whz.png);
			    width: 100%;
			    height: 100%;
			    background-repeat: no-repeat;
			    background-position: center;
			    background-size: 80%;
				    z-index: 111;
					opacity: 0.8;
	}
	.game_maintain.game_maintain_icon1::after {
		content: '';
		    font-size: 30px;
		    position: absolute;
		    top: 58%;
		    left: 50%;
		    transform: translate(-50%, -50%);
		    font-weight: 700;
			color:red;
			/* background-image: url(./assets/whz.png); */
			background-image: url(/assets/whz.png);
			    width: 100%;
			    height: 100%;
			    background-repeat: no-repeat;
			    background-position: center;
			    background-size: 74%;
				    z-index: 111;
	}
	.game_maintain.game_maintain_icon1 img{
		top: -4px !important;
	}
	.game_maintain.game_maintain_icon .tabs_logo{
		background-position: 0 -10px;
		background-repeat: no-repeat;
	}
	.game_maintain.game_maintainTY::after {
		top: 60%  !important;
		width: 60%  !important;
	}
	.game_maintain.game_maintainDz::after  {
		top: 30%  !important;
		width: 60%  !important;
	}
	.game_maintain.game_maintainBy::after  {
		top: 86%  !important;
		width: 76%  !important;
	}
	.game_maintain.game_maintainDD::after  {
		top: 88%  !important;
		width: 90%  !important;
	}
	
	body .swiperdom .swiper-button-prev.swiper-button-prev1.swiper-button-prev11 {
		left: 0px !important;
	}

	body .swiperdom .swiper-button-next.swiper-button-next1.swiper-button-next11 {
		right: 0px !important;
	}

	.css1_models .anticon-question-circle {
		display: none;
	}

	.css1_models .ant-modal-confirm-title {
		font-size: 18px !important;
	}

	.css1_models .ant-modal-confirm-btns {
		float: initial !important;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.css1_models .ant-modal-confirm-btns button.ant-btn-primary {
		background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
	}

	.css1_models .ant-modal-confirm-btns button {
		width: 46%;
		height: 50px;
		font-size: 16px !important;
	}

	.css1_models .ant-modal-confirm-content {
		margin-left: 0px !important;
	}

	.selectcss .ant-select-selection__rendered {
		margin-left: 20px;

	}

	.selectcss .ant-select-selection__placeholder {
		color: #c2ccd1 !important;
	}

	.ainputnumber .ant-input-number-input {
		height: 50px;
	}
	
	.ainputnumber .ant-input {
		height: 50px !important;
	}
	
	.codemodel1 .ant-modal-close-x {
		position: relative;
		top: -14px;
		right: -14px;
	}

	.slide-verify-slider-mask-item {
		border-radius: 100px;
	}

	.slide-verify-slider {
		border-radius: 100px;
		overflow: hidden;
	}

	.slide-verify-slider {
		margin-top: 10px !important;
	}

	.ant-spin {
		color: #3181ff !important;
	}

	.ant-spin-dot-item {
		color: #3181ff !important;
	}

	.checkBox22 .ant-checkbox {
		transform: scale(0.9);
	}

	.swiperdom .swiper-button-prev.swiper-button-prev1 {
		left: 200px !important;
	}

	.swiperdom .swiper-button-next.swiper-button-next1 {
		right: 200px !important;
	}

	#app {
		height: 100%;
	}

	.testbo .ant-modal-confirm-title {
		font-weight: 700 !important;
		font-size: 18px !important;
	}

	.testbo .ant-modal-confirm-content {
		padding: 10px 0px !important;
		font-size: 14px !important;
	}

	.ant-tooltip-inner {
		background-color: #fff !important;
	}

	.ant-tooltip-arrow::before {
		background-color: #fff !important;
	}

	.ant-modal-content {
		border-radius: 10px !important;
		overflow: hidden;
	}

	.loading-app {
		background: rgba(0, 0, 0, .5);
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1300;
		overflow: hidden;
	}

	.loading-app div {
		height: 96px;
		width: 96px;
		position: relative;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	.loading-app div img {
		height: 96px;
		width: 96px;
		display: block;
	}



	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	html,
	body,
	div,
	span,
	applet,
	object,
	iframe,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	pre,
	a,
	abbr,
	acronym,
	address,
	big,
	cite,
	code,
	del,
	dfn,
	em,
	img,
	ins,
	kbd,
	q,
	s,
	samp,
	small,
	strike,
	strong,
	sub,
	sup,
	tt,
	var,
	b,
	u,
	i,
	center,
	textarea,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	fieldset,
	form,
	label,
	legend,
	table,
	caption,
	tbody,
	tfoot,
	thead,
	tr,
	th,
	td,
	article,
	aside,
	canvas,
	details,
	embed,
	figure,
	figcaption,
	footer,
	header,
	hgroup,
	menu,
	nav,
	output,
	ruby,
	section,
	summary,
	time,
	mark,
	audio,
	video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
		user-select: text;
	}

	/* HTML5 display-role reset for older browsers */
	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	hgroup,
	menu,
	nav,
	section {
		display: block;
	}

	html,
	body {
		width: 100%;
		height: 100%;
		/*font-family: "helvetica neue,Helvetica,pingfang sc,hiragino sans gb,microsoft yahei,å¾®è½¯é›…é»‘,Arial,sans-serif";*/
		font-size: 14px;
		line-height: 1 !important;
		/* scroll-behavior: smooth; */
	}

	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	body {
		line-height: 1;
	}

	ol,
	ul {
		list-style: none;
	}

	blockquote,
	q {
		quotes: none;
	}

	blockquote:before,
	blockquote:after,
	q:before,
	q:after {
		content: "";
		content: none;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	a {
		text-decoration: none;
		transition: all 0.3s ease;
		color: #333;
	}

	a:hover {
		color: #666;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/*input:-webkit-autofill, 
 textarea:-webkit-autofill, 
 select:-webkit-autofill { 
       -webkit-box-shadow: 0 0 0 1000px white inset; 
}
 input[type=text]:focus, input[type=password]:focus, textarea:focus {
      -webkit-box-shadow: 0 0 0 1000px white inset; 
}*/
	input::-webkit-input-placeholder,
	input:placeholder-shown {
		font-size: 12px;
	}

	input {
		outline: none;
	}

	input::-webkit-input-placeholder {
		/* WebKit browsers */
		color: #fff;
		font-size: 14px;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #fff;
		font-size: 14px;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #fff;
		font-size: 14px;
	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #fff;
		font-size: 14px;
	}

	body::-webkit-scrollbar {
		display: none;
	}

	body p {
		margin-bottom: 0;
	}

	body ul {
		margin-bottom: 0;
	}

	/* .swiper-pagination {
		bottom: 60px !important;
	} */

	.swiper-pagination-bullet {
		width: 19px !important;
		height: 19px !important;
		/* background-image: url(./assets//banner/dd.png) !important; */
		background-image: url(/assets//banner/dd.png) !important;
		background-color: initial !important;
		background-size: 100% 100% !important;
		background-size: contain !important;
		opacity: 1 !important;
		transition: .2s !important;
		margin: 0 30px 0 0 !important;
	}

	.swiper-pagination-bullet-active {
		/* background-image: url(./assets//banner/dd1.png) !important; */
		background-image: url(/assets//banner/dd1.png) !important;
	}

	.swiper-button-prev1:after {
		opacity: 0;
	}

	.swiper-button-next1:after {
		opacity: 0;
	}

	.swiper-button-prev.swiper-button-prev1 {
		width: 39px;
		height: 80px;
		/* background-image: url(./assets//banner/lef.png) !important; */
		background-image: url(/assets//banner/lef.png) !important;
		background-size: cover;
		left: 0px !important;
	}

	.swiper-button-prev.swiper-button-prev1:hover {
		/* background-image: url(./assets//banner/lle1.png) !important; */
		background-image: url(/assets//banner/lle1.png) !important;
	}

	.swiper-button-next.swiper-button-next1:hover {
		/* background-image: url(./assets//banner/rre1.png) !important; */
		background-image: url(/assets//banner/rre1.png) !important;
	}

	.swiper-button-next.swiper-button-next1 {
		width: 39px;
		height: 80px;
		/* background-image: url(./assets//banner/ref.png) !important; */
		background-image: url(/assets//banner/ref.png) !important;
		background-size: cover;
		right: 0px !important;
	}

	.swiperdom {
		cursor: pointer;
	}


	input:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px white inset;
	}

	.swiperdom .swiper-button-prev1 {
		opacity: 0;
	}

	.swiperdom .swiper-button-next1 {
		opacity: 0;
	}

	.swiperdom:hover .swiper-button-prev1 {
		opacity: 1;
	}

	.swiperdom:hover .swiper-button-next1 {
		opacity: 1;
	}

	.swiperdom.swiperdom1 .swiper-slide-active {
		
	}

	.swiperdom .swiper-slide-active {
		
	}

	.nocss .swiperdom .swiper-slide-active {
		
	}

	@keyframes ddete {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	/* .swiper-pagination-bullet-active:after {
		width: 0px;
		height: 100%;
		content: "";
		position: absolute;
		left: 0px;
		top: 0px;
		background-color: #2c9bfd;
		-webkit-animation: right_anim1 7s ease;
		animation: right_anim1 7s ease;
	}
 */
	.veuvePageDesc {
		-webkit-animation: right_anim 0.4s ease;
		animation: right_anim 0.4s ease;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	}

	.veuvePageTitle {
		-webkit-animation: right_anim 0.4s ease;
		animation: right_anim 0.4s ease;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	}

	.veuvePageGames {
		-webkit-animation: right_anim 0.4s ease;
		animation: right_anim 0.4s ease;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	}

	.butGame1 {
		-webkit-animation: right_anim 0.4s ease;
		animation: right_anim 0.4s ease;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	}

	.renderLeftContent {
		-webkit-animation: right_anim 0.4s ease;
		animation: right_anim 0.4s ease;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	}

	@-webkit-keyframes right_anim {
		from {
			-webkit-transform: translateX(10%);
			transform: translateX(10%);
			opacity: 0;
		}

		to {
			-webkit-transform: translateX(0);
			transform: translateX(0);
			opacity: 1;
		}
	}

	@keyframes right_anim {
		from {
			-webkit-transform: translateX(10%);
			transform: translateX(10%);
			opacity: 0;
		}

		to {
			-webkit-transform: translateX(0);
			transform: translateX(0);
			opacity: 1;
		}
	}

	@-webkit-keyframes right_anim1 {
		from {
			width: 0%;
		}

		to {
			width: 100%;
		}
	}

	@keyframes right_anim1 {
		from {
			width: 0%;
		}

		to {
			width: 100%;
		}
	}

	.rightobbox {
		background-color: #fff;
		height: auto;
		width: 960px;
		min-height: 818px;
		padding: 30px 30px 40px;
		border-radius: 10px;
		overflow: hidden;
		-webkit-box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, .05);
		box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, .05);
		margin-bottom: 20px;
		margin-left: 20px;
	}

	.title-bo2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-between;
		margin: 0 auto 30px;
	}

	.title-bo2 .left {
		display: flex;
		align-items: center;
	}

	.title-bo2 .left p {
		font-size: 24px;
		font-weight: bold;
		margin-right: 10px;
		color: #333;
	}

	.title-bo2 .left span {
		color: #94a6b7;
		font-size: 14px;
		font-size: 14px;
		position: relative;
		top: 6px;
	}

	.title-bo2 .right {
		display: flex;
		align-items: center;
	}

	.tk_jr {}

	.tk_jr .Button {
		cursor: pointer;

	}

	.tk_jr .Button3 {
		border: none;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;
	}

	.tk_jr .Button3:hover {
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
		background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
	}

	.tk_jr .Button3.disabled {
		opacity: .4;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
	}

	.tabsob {
		float: right;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: -8px;
		cursor: pointer;

	}

	.tabsob div {
		width: 120px;
		height: 40px;
		font-size: 14px;
		text-align: center;
		line-height: 40px;
		-webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
		box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
		border: solid 1px #f0f3f7;
		background-color: #fff;
		box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .05);
		color: #333;
	}

	.tabsob div:last-child {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		border-left: none;
	}

	.tabsob div.active {
		background-image: -webkit-gradient(linear, left top, right top, color-stop(10%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 10%, #3678fe 100%);
		background-image: linear-gradient(to right, #5fabff 10%, #3678fe 100%);
		background-color: transparent;
		-webkit-box-shadow: 0 10px 30px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 30px 0 rgba(60, 142, 254, .2);
		color: #fff;
		font-weight: bold;
		border-left: none;
	}

	.ant-modal-header {
		border-bottom: 0px !important;
	}

	.ant-modal-title {
		height: 20px;
		line-height: 20px;
		font-size: 18px !important;
		font-weight: bold !important;
		color: #333;
		overflow: hidden;
		max-width: 450px;
		text-overflow: ellipsis;
		/* top: 20px; */
		position: relative;
		white-space: nowrap;
		/* left: 20px; */
	}

	.ant-select-selection--single {
		height: 40px !important;
	}

	.ant-select-selection__rendered {
		line-height: 38px !important;
	}

	.ant-radio-button-wrapper {
		height: 40px !important;
		line-height: 38px !important;
	}

	.ant-input {
		height: 40px !important;
	}

	.ant-table-thead>tr>th {
		color: rgb(51, 51, 51) !important;
		font-size: 14px !important;
		font-weight: 700 !important;
	}

	.ant-table-placeholder {
		border-bottom: 0px !important;
	}

	.my-center-page input::-webkit-input-placeholder {
		/* WebKit browsers */
		color: #C2CCD1;
		font-size: 14px;
	}

	.my-center-page input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #C2CCD1;
		font-size: 14px;
	}

	.my-center-page input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #C2CCD1;
		font-size: 14px;
	}

	.my-center-page input:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #C2CCD1;
		font-size: 14px;
	}

	.forminut input::-webkit-input-placeholder {
		/* WebKit browsers */
		color: #C2CCD1;
		font-size: 14px;
	}

	.forminut input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #C2CCD1;
		font-size: 14px;
	}

	.forminut input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #C2CCD1;
		font-size: 14px;
	}

	.forminut input:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #C2CCD1;
		font-size: 14px;
	}

	.text-model .date {
		color: #94a6b7;
		font-size: 12px;
		position: relative;
		top: 5px;
		font-weight: normal;
		overflow: hidden;
		max-width: 450px;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: inline-block;
		position: relative;
		top: -14px;
	}

	.text-model .trext {
		padding: 0 5px;
		color: rgb(51, 51, 51);
		min-height: 150px;
	}

	.slide-right-leave-to {
		opacity: 0;
		transform: translateX(200%)
	}

	.slide-right-enter {
		opacity: 0;
		transform: translateX(-200%)
	}





	.t1-enter-acitve {
		transition: all 0.3s linear;
		transform: translateY(0%);
		opacity: 0;
	}

	.t1-leave-active {
		transition: all 0.3s linear;
		opacity: 0;
	}

	.t1-leave,
	.t1-leave-leave {
		opacity: 1;
	}

	.t1-leave-to {
		transform: translateY(0%);
		opacity: 0;
	}

	.rgihtbox1>div:nth-child(2) {
		display: none;
	}

	.text-model .group {
		width: 100%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-flow: row nowrap;
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		margin-top: 15px;
	}

	.text-model .Button1 {
		cursor: pointer !important;
		border: solid 1px #d8dce7;
		color: #333;
		background-color: #fff;
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;

	}

	.text-model .Button1:hover {
		background-color: #f8f8f8;
		border-color: #f0f0f0;
		cursor: no-drop;
		border: solid 1px #3678fe;
	}


	.text-model .disabled2 {
		cursor: not-allowed !important;
		color: #94a6b7;
		background-color: #f8f8f8;
		border-color: #f0f0f0;
	}
</style>
