<template>
	<img v-lazy="newImg" :class="classText" :style="styleText">
</template>

<script>
export default {
  name: 'myimg',
  props: {
	srcText: String,
	classText: String,
	styleText: String
  },
  computed: {
	 newImg () {
		 return '/assets/'+ this.srcText
	 }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
