export const main = {
	close: '關閉',
	perv: '上一條',
	next: '下一條',
	all: '更多',
	bulletin: '公告消息',
	login_ts: '請登錄賬號後再遊戲！',
	okText: '確定',
	wxts: '溫馨提示',
	cancelText: '取消',
	homeText: '首頁',
	login: '登錄',
	reg: '註冊',
	dzyx: '電子遊戲',
	yhhd: '優惠活動',
	from: {
		password: '密碼',
		username: '賬號',
		errname: '賬號：4-11位，最少2個字母+數字組合，首位為字母',
		errpass: '密碼：請輸入6-12位字母+數字的組合'
	},
	header: {
		kf: '客服',
		hy: '合營',
		yh: '優惠',
	},
	my: {
		deposit: '存款',
		transfer: '轉賬',
		withdrawals: '取款',
		myinfo: '個人信息',
		avate: '更換頭像',
		tzjl: '投註記錄',
		jyjl: '交易記錄',
		xxzx: '消息中心',
		ologin: '退出登錄',
		help: '幫助中心',
		cs: '在線客服',
		app: '全站APP'
	},
	message: {
		tiplogin: '登錄已過期，請重新登錄！',
		cancelText: '知道了',
		linkLoginBtn: '前往登錄',
		bttip: '賬號已在其他地方登錄！',
		loginSucc: '賬號登錄成功'
	},
    home: {
		sponsor_list: [{
			title: 'USDT充值提款更快捷',
			des: '支持多種虛擬幣充提'
		},{
			title: '合作26家銀行充值秒到賬',
			des: '轉數快充值更快捷'
		},{
			title: '7-11便利店現金充值',
			des: '接受大額存款'
		}],
		app: {
			title: '全站APP',
			des: '全球首家一體化娛樂原生APP，盡顯流暢，完美操作。海量體育，電競頂尖賽事，真人娛樂，彩票投註及電子遊藝等，最新最全娛樂項目盡在掌中體驗掃碼下載，即刻擁有！',
			app: {
				title: '掃碼下載',
				des: '支持iOS&Android'
			},
			h5: {
				title: '直接訪問',
				des: '無需下載，手機輸入網址即可'
			}
		},
		service: {
			title: '',
			m: '秒',
			j: '家',
			t1: '平均存款時間',
			t2: '合作支付平臺',
			t3: '平均取款時間',
			t4: '合作遊戲平臺',
			
			de1: {
				name: '極速存取轉款',
				des: '最新技術自主研發的財務處理系統真正做到極速存、取、轉獨家網絡優化技術，為您提供一流的遊戲體驗，最大優化網絡延遲。'
			},
			de2: {
				name: '海量賽事種類',
				des: '每天為您提供近千場精彩體育賽事，更有真人、彩票、電子遊戲等多種娛樂方式選擇，讓您擁有完美遊戲體驗。'
			},
			de3: {
				name: '加密安全管理',
				des: '獨家開發，采用128位加密技術和嚴格的安全管理體系，客戶資金得到最完善的保障，讓您全情盡享娛樂、賽事投註、無後顧之憂！'
			},
			de4: {
				name: '三端任您選擇',
				des: '引領市場的卓越技術，自主研發了全套終端應用，讓您隨時隨地，娛樂投註隨心所欲！7x24小時在線客服提供最貼心、最優質的服務。'
			}
		},
	},
	footer: {
		ti1: '擁有歐洲馬耳他（MGA）和菲律賓政府競猜委員會（PAGCOR）頒發的合法執照。' ,
		ti2: '註冊於英屬維爾京群島，是受國際行業協會認可的合法公司。進行註冊並娛樂前，請確保您年滿18周歲！',
		ti3: '馬耳他牌照（MGA）認證',
		ti4: '英屬維爾京群島（BVI）認證',
		ti5: '菲律賓（PAGCOR）監督競猜牌照',
	},
	help: {
		ti1: '取款幫助',
		ti2: '關於我們',
		ti3: '聯絡我們',
		ti4: '免費代理',
		ti5: '存款幫助',
		ti6: '常見問題',
		ti7: '娱乐责任',
	},
	game: {
		ty: {
			homename: '體育賽事',
			name: '體育',
			des: '業內最高賠率，覆蓋世界各地賽事，讓球、大小、半全場、波膽、單雙、總入球、連串過關等多元競猜。更有動畫直播、視頻直播，讓您輕松體驗聊球投註，樂在其中。 ',
			sbo: {
				name: 'SBO體育',
				des: '最全精彩賽事，多元競猜玩法，市場最優賠率，註單秒接體驗，高清直播讓您如臨現場，全程精彩不容錯失，致力成為玩家最喜愛的專業體育平臺。',
			},
			ob: {
				name: '熊貓體育',
				des: '奇幻賽事、特別投註 ，各種趣味玩法，最全賽事覆蓋助你花式收米贏到人生巔峰。'
			},
			hg: {
				name: '皇冠體育',
				des: '最多投註類型，最強投註體驗，支持提前兌現，收米也要快人一步，想你所想，投你所愛。'
			},
			bbin: {
				name: 'BBIN體育',
				des: '最全精彩賽事，多元競猜玩法，市場最優賠率，註單秒接體驗，高清直播讓您如臨現場，全程精彩不容錯失，致力成為玩家最喜愛的專業體育平臺。'
			}
		},
		zr: {
			homename: '真人娛樂',
			name: '真人',
			des: '互動娛樂在線直播平臺，讓玩家遊戲的同時，觀賞美女主播表演、參與互動遊戲。設有多個真人荷官桌臺，包括：百家樂、競咪、龍虎、骰寶、輪盤等多款遊戲。 ',
			ob: {
				name: '多寶真人',
				des: '多寶真人視訊為官方直營，最美荷官在線互動，帶您玩轉百家樂、骰寶、輪盤、牛牛、炸金花等多款真人視訊遊戲, 國際標準、公平公正，極致享受盡在OB真人。',
			},
			dg: {
				name: 'DG真人',
				des: 'DG真人視訊為亞洲最具潛力的真人視訊品牌，提供百家樂、極速百家樂、共咪百家樂、多彩百家樂、龍虎、骰寶、輪盤、牛牛、炸金花等多樣真人視訊遊戲。DG真人視訊采用國際標準的真人遊戲攝影棚，全場嚴密監控，流程公正公開，提供最公平的遊戲環境。超低延遲高清視頻，即時呈現！',
			},
			pp: {
				name: 'PP真人',
				des: '高清性感真人荷官直播娛樂場，獨創真人融合棋牌電遊風格。設有百家樂、牛牛、龍虎、炸金花、色碟、番攤等上百真人荷官桌臺，專為亞洲人研發設計的投註界面，細致版面、極致操作讓您擁有身臨其境的體驗！',
			},
			evo: {
				name: 'EVO真人',
				des: '以高規格打造的AG真人配備最先進的設備以及富麗堂皇的裝潢，為玩家打造絕佳真人視訊遊戲體驗。遊戲選擇多樣化，包括備受歡迎的百家樂、骰寶、輪盤等，加上全天侯監控管理，為玩家提供穩定而流暢的遊戲服務。',
			},
			wm: {
				name: 'WM真人',
				des: 'WM自2012年成立以來即致力創造富遊戲性與創意兼具的移動娛樂平臺，不僅提供專業的技術支持和穩定的頂級設備，並支持多項平臺和榮獲多項國際許可認證，提供玩家最值得信任的娛樂環境。另外，數百位受過專業培訓的荷官和仿真的實體場館環境，更可讓玩家盡享遊戲樂趣。',
			},
			sexy: {
				name: 'SEXY真人',
				des: '超高清視頻、完美主播音質等等，讓可愛的性感女主播與玩家親密互動、提供讓人著迷的遊戲體驗！通過我們的新聊天室與我們的可愛女主播和性感荷官親密互動、交個朋友、更近距離接觸和個性化服務。',
			},
			bbin: {
				name: 'BBIN真人',
				des: 'BBIN真人視訊為官方直營，最美荷官在線互動，帶您玩轉百家樂、骰寶、輪盤、牛牛、炸金花等多款真人視訊遊戲, 國際標準、公平公正，極致享受盡在BBIN真人。'
			}
		},
		qp: {
			homename: '棋牌遊戲',
			name: '棋牌',
			des: '提供市面上熱門遊戲種類，選擇全面多元，應有盡有玩家能不斷遊戲不感無趣！搶莊牛牛、龍虎鬥，多款棋牌任君選，好友相約競技，遊戲改變生活。 ',
			ob: {
				name: 'DB棋牌',
				des: 'DB棋牌為官方直營，歐洲最高級別安全認證，熱門棋牌品類豐富，聆聽悅耳音樂，感受非同凡響極致體驗，讓遊戲改變生活，盡在OB棋牌。',
			},
			ky: {
				name: 'KY棋牌',
				des: '「棋」樂無窮，「牌」趣橫生。全新遊戲畫風，給您帶來前所未有的視覺盛宴。獨創趣味玩法，讓您感受其樂無窮的遊戲體驗。博雅棋牌，邀您旗開得勝！',
			},
			yl: {
				name: '樂遊棋牌',
				des: '搶莊牛牛、龍虎鬥，多款棋牌任君選! 棋牌彰顯胸懷，競技娛樂燒腦，好友相約競技，遊戲改變生活！',
			},
			vg: {
				name: 'VG棋牌',
				des: '雙贏棋牌提供市面上熱門遊戲種類，選擇全面多元，應有盡有，玩家能不斷遊戲不感無趣！',
			},
			mt: {
				name: '美天棋牌',
				des: '美天棋牌為官方直營，歐洲最高級別安全認證，熱門棋牌品類豐富，聆聽悅耳音樂，感受非同凡響極致體驗，讓遊戲改變生活，盡在美天棋牌。',
			}
		},
		dj: {
			homename: '電子競技',
			name: '電競',
			des: '提供所有大型賽事，每月玩家可期待超過百場比賽及上萬盤口！擁有令人驚嘆的視覺界面及高效的用戶體驗，所以能讓您輕松上手，一目了然，輕松投註。 ',
			ob: {
				name: 'DB電競',
				des: '全球頂尖賽事全覆蓋，提供專業的賠率數據，結算賽果數據。日均提供100+電競賽事，50+電競新玩法，10+電競滾球盤，業內最佳盤口。',
			},
			lh: {
				name: '雷火電競',
				des: '創新電競競猜模式，時時滾球，獨創自由串關。註單秒確認，熱門賽事秒結算，獨家滾球助您嗨翻全場。絕妙暢爽體驗，一切競有可能！',
			}
		},
		cp: {
			homename: '彩票投註',
			name: '彩票',
			des: '超過百種彩票玩法任您贏！全球各彩票玩家提供了豐富多樣的遊戲內容，致力為玩家打造高品質的娛樂環境，安心樂享遊戲空間，只為公平、公正的開獎結果。 ',
			ob: {
				name: 'DB彩票',
				des: '「棋」樂無窮，「牌」趣橫生。全新遊戲畫風，給您帶來前所未有的視覺盛宴。獨創趣味玩法，讓您感受其樂無窮的遊戲體驗。博雅棋牌，邀您旗開得勝！',
			},
			bbin: {
				name: 'BBIN彩票',
				des: '雙贏棋牌提供市面上熱門遊戲種類，選擇全面多元，應有盡有，玩家能不斷遊戲不感無趣！',
			}
		},
		dz: {
			homename: '電子遊藝',
			name: '電子',
			des: '提供各類經典老虎機遊戲、刮刮樂、棋牌、街機等遊戲，更多免費遊戲，爆分大獎等你來。 ',
			ob: {
				name: 'DB電子',
			},
			png: {
				name: 'PNG電子',
			},
			jdb: {
				name: 'JDB電子'
			},
			pgs: {
				name:'PGS電子'
			},
			pp: {
				name: 'PP電子'
			},
			cq: {
				name: '傳奇電子'
			},
			joker: {
				name: 'JOKER電子'
			},
			mw: {
				name: 'MW電子'
			},
			live: {
				name: 'LIVE22電子'
			},
			ns: {
				name: 'NESTSPIN電子'
			},
			bbin: {
				name: 'BBIN電子'
			}
		},
		by: {
			homename: '',
			name: '捕魚',
			des: '',
			ob: {
				name: 'DB捕魚',
			},
			mw: {
				name: 'MW捕魚',
			},
		}
	},
	
}