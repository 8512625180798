/**
 * 网站配置管理
 */
import http from '@/http.js'
 export default {
     namespaced: true,
     state: {
        banks: [],
		config: {},
		gameConfig: {
			"体育": [],
			"真人": [],
			"棋牌": [],
			"电竞": [],
			"彩票": [],
			"电子": [],
			"捕鱼": [],
			"by": []
		},
		bannerList: []
     },
     mutations: {
         SET: (state, obj) => {
             state[obj.key] = obj.value;
         }
     },
     actions: {
		getBanner ({ commit }, data) {
			http({
			 	method: 'get',
			 	url: '/banner',
			 	data: {
			 		type: 1
			 	}
			}).then((res) => {
			 	if (res.code == 200) {
					let newBannerList = res.data
					if (res.data) {
						newBannerList = res.data.map((v) => {
							return {
								...v,
								loadImg: false
							}
						})
					}
			 		commit('SET', { key: 'bannerList', value: newBannerList });
			 	} else {
			 		
			 	}
			})
		},
        /* 存储配置的银行卡列表 */
        setConfig({ commit }, data) {
			http({
			 	method: 'get',
			 	url: '/config',
			 	data: {
			 		
			 	}
			}).then((res) => {
			 	if (res.code == 200) {
					document.title = res.data.site_title
			 		commit('SET', { key: 'banks', value: res.data.bank });
					commit('SET', { key: 'config', value: res.data });
			 	} else {
			 		
			 	}
			})
         },
		 setGame ({ commit }, data) {
			 const getGame = () => {
				 http({
				  	method: 'get',
				  	url: '/allGame',
				  	data: {
				  		
				  	}
				 }).then((res) => {
				  	if (res.code == 200) {
						
						let listData = {
							JDB: [],
							CQ9: []
						}
						res.data["6"].forEach((item) => {
							if (listData[item.plat_type]) {
								listData[item.plat_type] = [...listData[item.plat_type], item]
							} else {
								listData[item.plat_type] = [item]
							}
						})
				  		let gameConfig = {
				  			"体育": res.data["4"],
				  			"真人": res.data["1"],
				  			"棋牌": res.data["7"],
				  			"电竞": res.data["5"],
				  			"彩票": res.data["3"],
				  			"电子": res.data["2"],
				  			"捕鱼": listData,
							"by": res.data["6"],
				  		}
				 					commit('SET', { key: 'gameConfig', value: gameConfig });
				 					setTimeout(() => {
				 						commit('SET', { key: 'gameConfig', value: gameConfig });
				 					}, 2000)
				  	} else {
				  		
				  	}
				 })
			 }
			 getGame()
			 setInterval(() => {
				getGame() 
			 }, (1000 * 60))
		 }
     }
 }
 
 