/**
 * 网站配置管理
 */
import http from '@/http.js'
let stem2000 = null
 export default {
     namespaced: true,
     state: {
        balance: 0.00,
		gidBalance: 0.00,
		avatarImg: '',
		userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
		
		transferModelShow: false,
		transferModelInfo: {
			platType: '',
			gameName: '',
		},
		jokerModelShow: false,
		
		unread: 0
     },
     mutations: {
        SET: (state, obj) => {
            state[obj.key] = obj.value;
        }
     },
     actions: {
		setTransferModelShow ({ dispatch, commit, state }, data) {
			commit('SET', { key: 'transferModelShow', value: data });
			dispatch('getNewGidBalance')
		},
		setJokerModelShow  ({ dispatch, commit, state }, data) {
			commit('SET', { key: 'jokerModelShow', value: data });
			dispatch('getNewGidBalance')
		},
		// 进入游戏
		linkGamePage ({ dispatch, commit, state, rootState }, data) {
			if (state.userInfo.is_auto == 0 && !state.transferModelShow) {
				commit('SET', { key: 'transferModelShow', value: true });
				commit('SET', { key: 'transferModelInfo', value: data });
			} else {
				let jokerNum = 0
				rootState.config.gameConfig['电子'].forEach((v) => {
					if (v.name.indexOf('JOKER') >= 0 && v.maintain === 0) {
						jokerNum = jokerNum + 1
					}
				})
				if (data.platType === 'JOKER' && data.gameName === 'JOKER电子') {
					if (jokerNum === 1) {
						dispatch('getNewGidBalanceTime')
						window.open(data.href, "_blank");
					} else {
						commit('SET', { key: 'transferModelInfo', value: data });
						commit('SET', { key: 'jokerModelShow', value: true });
					}
				} else {
					dispatch('getNewGidBalanceTime')
					window.open(data.href, "_blank");
				}
			}
		},
		// 取消循环获取用户余额
		clearIntervalGetBalance({ dispatch, commit }, data) {
			clearInterval(stem2000)
		},
		// 循环获取用户余额
		intervalGetBalance({ dispatch, commit }, data) {
			stem2000 = setInterval(() => {
				dispatch('setBalance', (res) => {
					if (res.code != 200) {
						dispatch('clearIntervalGetBalance')
					}
				})
				dispatch('getUnreadNumber')
			}, 3000)
		},
        /* 存储用户余额 */
        setBalance({ commit }, callback) {
			if (stem2000 && !callback) {
				clearInterval(stem2000)
				stem2000 = null
			}
			http({
			 	method: 'POST',
			 	url: '/member/balance',
			 	data: {
			 		
			 	}
			}).then((res) => {
			 	if (res.code == 200) {
			 		commit('SET', { key: 'balance', value: parseFloat(res.data) || 0 });
			 	} else {
			 		
			 	}
				callback && callback(res)
			})
        },
		getNewGidBalanceTime({ commit, dispatch }, callback) {
			setTimeout(() => {
				dispatch('getNewGidBalance')
			}, 10000)
		},
		getNewGidBalance({ commit }, callback) {
			http({
			 	method: 'POST',
			 	url: '/member/allGameBalance',
			 	data: {
			 		
			 	}
			}).then((res) => {
			 	if (res.code == 200) {
			 		commit('SET', { key: 'gidBalance', value: parseFloat(res.data) || 0 });
			 	} else {
			 		
			 	}
				callback && callback(res)
			})
		},
		getUserInfo ({ commit }, data) {
			http({
			 	method: 'POST',
			 	url: '/member/info',
			 	data: {
			 		
			 	}
			}).then((res) => {
			 	if (res.code == 200) {
			 		commit('SET', { key: 'avatarImg', value: res.data.avatar });
					commit('SET', { key: 'userInfo', value: res.data });
					// console.log(res.data, 'res.datares.datares.datares.data')
					localStorage.setItem('userInfo', JSON.stringify(res.data))
			 	} else {
			 		
			 	}
			})
		},
		setUserInfo ({ commit }, data) {
			http({
			 	method: 'POST',
			 	url: '/member/info',
			 	data: {
			 		
			 	}
			}).then((res) => {
			 	commit('SET', { key: 'userInfo', value: data });
			})
		},
		async getUnread ({ commit }, data) {
			let res = await http({
			 	method: 'POST',
			 	url: '/member/unread',
			 	data: {
			 		
			 	}
			})
			commit('SET', { key: 'unread', value: (typeof res.data === 'number') ? res.data : 0 });
		},
		getUnreadNumber ({ commit, dispatch }, data) {
			dispatch('getUnread')
		}
     }
 }
 
 