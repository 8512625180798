export default {
    banks: state => state.config.banks,
	config: state => state.config.config,
	gameConfig: state => state.config.gameConfig,
	balance: state => state.user.balance,
	gidBalance: state => state.user.gidBalance,
	avatarImg: state => state.user.avatarImg,
	userInfo: state => state.user.userInfo,
	bannerList: state => state.config.bannerList,
	transferModelShow: state => state.user.transferModelShow,
	transferModelInfo: state => state.user.transferModelInfo,
	unread: state => state.user.unread,
	jokerModelShow: state => state.user.jokerModelShow,
}
