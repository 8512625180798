import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


Vue.config.productionTip = false

// 图表
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 轮播图
import 'swiper/swiper-bundle.css';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
Vue.use(Antd);

Vue.prototype.$store = store;

import MyImg from '@/components/MyImg.vue'

Vue.component("MyImg", MyImg);//全局自定义组件
import slideVerify from "vue-monoplasty-slide-verify";
Vue.use(slideVerify);

import VueLazyload from "vue-lazyload";
const loadimage = require('./assets/tm.png')
Vue.use(VueLazyload, {
  preLoad: 1.3, //预加载的宽高比
  loading: loadimage, //图片加载状态下显示的图片
  // error: errorimage, //图片加载失败时显示的图片
  attempt: 1, // 加载错误后最大尝试次数
})

import vueI18n from 'vue-i18n'
Vue.use(vueI18n)
const i18n=new vueI18n({
	locale: 'hk',
	messages:{
		'hk':require('@/assets/lang/hk'),
	}
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
