import axios from 'axios'
import Vue from 'vue'
const WEBURL = window.BASEURLVAL;
// const WEBURL = "/api";
const http = ({
	method,
	url,
	data,
	ContentType
}) => {
	let par = {
		method: method,
		url: WEBURL + url,
	}

	if (method == 'post') {
		par['data'] = data
	}

	if (localStorage.getItem("access_token")) {
		par['headers'] = {
			...par['headers'],
			'Authorization': localStorage.getItem("access_token"),
			'access_token': localStorage.getItem("access_token"),
			'token': localStorage.getItem("access_token"),
		}
	}

	if (method == 'get') {
		par['params'] = data
	}
	return axios(par).then((res) => {
		if (res.data.code == 201) {
			if (window.location.href.indexOf('/login') >= 0) {} else {
				window.location.href = '/login'
			}
		}
		if (res.data.code === 5001001) {
			window.location.href = window.location.origin + '/maintain'
		}
		if (res.data.code === 401 && localStorage.getItem("access_token")) {
			Vue.prototype.$store.dispatch('user/setUserInfo', {})
			localStorage.removeItem('userInfo')
			localStorage.removeItem('access_token')
			localStorage.removeItem('is_alert_on_1')
			localStorage.removeItem('is_login_1')
			Vue.prototype.$confirm({
				title: '登錄已過期，請重新登錄！',
				content: h => '',
				cancelText: '知道了',
				okText: '前往登錄',
				onOk() {
					window.location.href = '/login'
				},
				onCancel() {
					if (window.location.href.indexOf('/Home') >= 0) {
						
					} else {
						window.location.href = '/Home'
					}
				},
				class: 'test',
			});
		}
		if (res.data.code === 4001 && localStorage.getItem("access_token")) {
			Vue.prototype.$store.dispatch('user/setUserInfo', {})
			localStorage.removeItem('userInfo')
			localStorage.removeItem('access_token')
			localStorage.removeItem('is_alert_on_1')
			localStorage.removeItem('is_login_1')
			Vue.prototype.$confirm({
				title: '賬號已在其他地方登錄！',
				cancelText: '知道了',
				okText: '前往登錄',
				onOk() {
					window.location.href = '/login'
				},
				onCancel() {
					if (window.location.href.indexOf('/Home') >= 0) {
						
					} else {
						window.location.href = '/Home'
					}
				},
				class: 'test',
			});
		}
		return res.data
	})
}

export default http
