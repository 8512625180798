import Vue from 'vue'
import VueRouter from 'vue-router'

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return routerPush.call(this, location).catch(err => {})
};
Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'index',
		component: () => import( /* webpackChunkName: "about" */ '../views/index/index.vue'),
		redirect: '/Home',
		children: [{
				path: '/Home',
				name: 'Home',
				component: () => import( /* webpackChunkName: "about" */ '../views/home/index.vue')
			},

			{
				path: '/sport',
				name: 'sport',
				component: () => import( /* webpackChunkName: "about" */ '../views/sport/index.vue')
			},
			{
				path: '/casino',
				name: 'casino',
				component: () => import( /* webpackChunkName: "about" */ '../views/casino/index.vue')
			},
			{
				path: '/gaming',
				name: 'gaming',
				component: () => import( /* webpackChunkName: "about" */ '../views/gaming/index.vue')
			},
			// {
			// 	path: '/realbet',
			// 	name: 'realbet',
			// 	component: () => import( /* webpackChunkName: "about" */ '../views/realbet/index.vue')
			// },
			{
				path: '/chess',
				name: 'chess',
				component: () => import( /* webpackChunkName: "about" */ '../views/chess/index.vue')
			},
			{
				path: '/lottery',
				name: 'lottery',
				component: () => import( /* webpackChunkName: "about" */ '../views/lottery/index.vue')
			},

			{
				path: '/activity',
				name: 'activity',
				component: () => import( /* webpackChunkName: "about" */ '../views/activity/index.vue')
			},
			{
				path: '/activity_detail',
				name: 'activity_detail',
				component: () => import( /* webpackChunkName: "about" */ '../views/activity_detail/index.vue')
			},
			
			{
				path: '/slot',
				name: 'slot',
				component: () => import( /* webpackChunkName: "about" */ '../views/slot/index.vue')
			},
			{
				path: '/fishing',
				name: 'fishing',
				component: () => import( /* webpackChunkName: "about" */ '../views/fishing/index.vue')
			},
			{
				path: '/app',
				name: 'app',
				component: () => import( /* webpackChunkName: "about" */ '../views/app_page/index.vue')
			},
			{
				path: '/my-center',
				name: 'my-center',
				component: () => import( /* webpackChunkName: "about" */ '../views/my-center/index.vue'),
				redirect: '/my-center/deposit',
				children: [{
						path: '/my-center/deposit',
						name: 'deposit',
						component: () => import( /* webpackChunkName: "about" */
							'../views/my-center/deposit/index.vue')
					},
					{
						path: '/my-center/transfer',
						name: 'transfer',
						component: () => import( /* webpackChunkName: "about" */
							'../views/my-center/transfer/index.vue')
					},
					{
						path: '/my-center/withdrawals',
						name: 'withdrawals',
						component: () => import( /* webpackChunkName: "about" */
							'../views/my-center/withdrawals/index.vue')
					},
					{
						path: '/my-center/transRecord',
						name: 'transRecord',
						component: () => import( /* webpackChunkName: "about" */
							'../views/my-center/transRecord/index.vue')
					},
					{
						path: '/my-center/betRecord',
						name: 'betRecord',
						component: () => import( /* webpackChunkName: "about" */
							'../views/my-center/betRecord/index.vue')
					},

					{
						path: '/my-center/banks',
						name: 'banks',
						component: () => import( /* webpackChunkName: "about" */
							'../views/my-center/banks/index.vue')
					},
					{
						path: '/my-center/mail',
						name: 'mail',
						component: () => import( /* webpackChunkName: "about" */
							'../views/my-center/mail/index.vue')
					},
					{
						path: '/my-center/password',
						name: 'password',
						component: () => import( /* webpackChunkName: "about" */
							'../views/my-center/password/index.vue')
					},
					{
						path: '/my-center/info',
						name: 'info',
						component: () => import( /* webpackChunkName: "about" */
							'../views/my-center/info/index.vue')
					},
					{
						path: '/my-center/myVip',
						name: 'myVip',
						component: () => import( /* webpackChunkName: "about" */
							'../views/my-center/myVip/index.vue')
					},
					{
						path: '/my-center/activity',
						name: 'activity',
						component: () => import( /* webpackChunkName: "about" */
							'../views/my-center/activity/index.vue')
					},
					{
						path: '/my-center/turnover',
						name: 'turnover',
						component: () => import( /* webpackChunkName: "about" */
							'../views/my-center/turnover/index.vue')
					}
				],
			},
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "about" */ '../views/login.vue')
	},
	{
		path: '/register',
		name: 'register',
		component: () => import( /* webpackChunkName: "about" */ '../views/register.vue')
	},
	{
		path: '/help',
		name: 'help',
		component: () => import( /* webpackChunkName: "about" */ '../views/help/index.vue')
	},
	{
		path: '/agent',
		name: 'agent',
		component: () => import( /* webpackChunkName: "about" */ '../views/agent/index.vue')
	},
	{
		path: '/game',
		name: 'game',
		component: () => import( /* webpackChunkName: "about" */ '../views/game/index.vue')
	},
	// {
	// 	path: '/about',
	// 	name: 'About',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import( /* webpackChunkName: "about" */ '../views/About.vue'),
	// }
]

const router = new VueRouter({
	mode: 'history',
	base: "/",
	scrollBehavior(to,from,savedPosition){
	  if(savedPosition){
		  return savedPosition;
	  }else{
		  return {x:0,y:0}
	  }
	},
	routes
})

// router.beforeEach((to, from, next) => {
// 	console.log(1)
// 	next()
// })

export default router
