/**
 * Vuex状态管理
 */
 import Vue from 'vue'
 import Vuex from 'vuex'
 import getters from './getters'
 import config from './modules/config'
 import user from './modules/user'
  
 Vue.use(Vuex);
 
 export default new Vuex.Store({
     state: {},
     mutations: {},
     actions: {},
     modules: {
        config,
		user
     },
     getters
 })
 